import styled from 'styled-components';
import { breakpoints } from 'constant/style';

interface SurveyVoucherPageWrapProps {
  $brandColor: string;
  $buttonColor?: string;
}

const SurveyVoucherPageWrap = styled.div<SurveyVoucherPageWrapProps>`
  margin-bottom: 50px;

  input[type='text'],
  input[type='number'],
  textarea {
    font-size: 16px !important;
  }

  .back-home-btn {
    border: 1px solid ${(props) => props.$brandColor};
    padding: 15px 50px;

    font-family: Gilroy-Medium;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--grey-dark);
    border-radius: 500px;
    transition: all 300ms ease 0s;

    :hover {
      background: ${(props) => props.$brandColor};
      color: var(--white);
    }
  }

  button {
    background: ${(props) => props.$brandColor} !important;
    border-color: ${(props) => props.$buttonColor || props.$brandColor} !important;

    span {
      color: ${(props) => props.$buttonColor ?? 'inherit'} !important;
      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0px;
  }
`;

export default SurveyVoucherPageWrap;
