import React, { CSSProperties } from 'react';
import { ChevronRightIcon, MapPinIcon, NavigationIcon } from 'assets/svg';
import styled from 'styled-components';
import { breakpoints } from 'constant/style';
import { DISTANCE_UNIT } from 'constant';

interface VoucherLocationCardProps {
  name: string;
  serviceOptions?: string[];
  address?: string;
  distance?: number | null;
  contactInfo?: any;
  style?: CSSProperties | undefined;
  onClick?: () => void;
}

const VoucherLocationCard = ({ name, address, distance, style, onClick }: VoucherLocationCardProps) => {
  return (
    <LocationCardWrap onClick={onClick} style={style}>
      <div className="location-content">
        <div className="location-title-wrap">
          <h5>{name}</h5>
        </div>
        <div className="location-info">
          {address && (
            <div className="location-address-distance-wrap">
              <div className="location-meta">
                <div className="icon-wrap">
                  <MapPinIcon width={27.19} height={21} />
                </div>
                <p>{address}</p>
              </div>
            </div>
          )}
        </div>
        {distance && !isNaN(distance) && (
          <div className="navigation-icon-wrap">
            <div className="navigation-icon">
              <NavigationIcon width={27.19} height={21} />
            </div>
            <div className="body-text distance-text">
              {distance.toFixed(1)} {DISTANCE_UNIT}
            </div>
          </div>
        )}
      </div>
      <ChevronRightIcon />
    </LocationCardWrap>
  );
};

const LocationCardWrap = styled.div`
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  transition: all 300ms ease 0s;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  display: flex;
  align-items: center;

  .location-content {
    flex: 1;
  }

  .location-title-wrap {
    h5 {
      font-family: 'Gilroy-Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: var(--slate-gray-dark);
    }
  }

  .location-info {
    margin-top: 15px;

    .location-address-distance-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .location-meta {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      flex: 1;
      align-items: center;

      .outlet-status {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .icon-wrap {
        width: 10%;
        display: flex;

        @media (min-width: ${breakpoints.desktop}) {
          width: 5%;
        }

        & > svg path {
          stroke: var(--grey-dark);
        }
      }

      p {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0px;
      }
    }
  }

  .navigation-icon-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;

    .navigation-icon {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .distance-text {
      font-family: 'Gilroy-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0px;
    }

    svg {
      margin-right: 5px;
    }
  }

  .location-action-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > button {
      margin-right: 10px;
    }
  }
`;

export default VoucherLocationCard;
