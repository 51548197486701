import { Rate } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { TextArea } from 'components/TextInput';
import Button from 'components/Button';
import { breakpoints } from 'constant/style';
import { UserOutlined } from '@ant-design/icons';
import { Staff } from 'utils/types';
import * as ga from 'lib/ga';
import { TRACKING } from 'constant/analytics';

interface StaffRateExperienceProps {
  selectedStaff: Staff | null;
  onSubmit: () => void;
  data: Record<string, any> | undefined;
  surveyQuestionAnswered: Record<string, any>;
  setSurveyQuestionAnswered: (values: Record<string, any>) => void;
}

const StaffRateExperience = ({
  data,
  selectedStaff,
  surveyQuestionAnswered,
  setSurveyQuestionAnswered,
  onSubmit,
}: StaffRateExperienceProps) => {
  const onRate = (value: number) => {
    ga.event(TRACKING.SURVEY.BUTTON.STAFF_RATING);

    setSurveyQuestionAnswered({
      ...surveyQuestionAnswered,
      answer: value.toString(),
    });
  };

  const onChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSurveyQuestionAnswered({
      ...surveyQuestionAnswered,
      detailedAnswer: e.target.value,
    });
  };

  const onFormSubmit = () => {
    ga.event(TRACKING.SURVEY.BUTTON.STAFF_RATING_SUBMIT);
    setSurveyQuestionAnswered({
      ...surveyQuestionAnswered,
      staff: {
        id: selectedStaff?.id,
        name: selectedStaff?.name,
        profile: selectedStaff?.profile_url,
      },
    });
    onSubmit();
  };

  return (
    <StaffRateExperienceWrap>
      <div className="staff-info">
        <h2>Which one of us would you like to rate?</h2>
        {selectedStaff?.profile_url ? (
          <img src={selectedStaff.profile_url} alt="avatar" />
        ) : (
          <div className="staff-placeholder">
            <UserOutlined style={{ fontSize: 60 }} />
          </div>
        )}
      </div>
      <div className="rate-field-wrap">
        <div className="staff-name">{selectedStaff?.name}</div>
        <Rate
          value={surveyQuestionAnswered?.answer}
          onChange={onRate}
          className="custom-stars"
          style={{ color: '#D84339' }}
        />
      </div>
      <div className="mb-20">
        <div className="label-text">{data?.questionData?.subtext}</div>
        <TextArea
          placeholder="Message"
          value={surveyQuestionAnswered?.detailedAnswer}
          onChange={onChangeMessage}
          rows={4}
        />
      </div>

      <Button block shape="round" onClick={onFormSubmit}>
        Continue
      </Button>
    </StaffRateExperienceWrap>
  );
};

const StaffRateExperienceWrap = styled.div`
  width: 60%;

  .staff-info {
    text-align: center;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      border-radius: 10px;
      width: 200px;
      height: 185px;
      object-fit: cover;
    }

    .staff-placeholder {
      width: 200px;
      height: 185px;
      border: 1px solid var(--grey-dark);
      border-radius: 10px;
      margin-bottom: 10px;
      background: var(--smoke-white);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .rate-field-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .staff-name {
      flex: 1;
      font-family: 'Gilroy-Medium';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      color: var(--slate-gray-dark);
    }

    .custom-stars {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  @media (max-width: ${breakpoints.laptop}) {
    width: 100%;
  }
`;

export default StaffRateExperience;
