import { BackArrowIcon } from 'assets/svg';
import { breakpoints } from 'constant/style';
import React from 'react';
import styled from 'styled-components';

interface BottomNavigationProps {
  onBack?: () => void;
  onContinue?: () => void;
  showContinueButton?: boolean;
  fixed?: boolean;
}

const BottomNavigation = ({ onBack, onContinue, showContinueButton = false, fixed = true }: BottomNavigationProps) => {
  return (
    <BottomNavigationWrap $fixed={fixed}>
      {onBack && (
        <div className="back-nav body-text" onClick={onBack}>
          <BackArrowIcon className="mr-10" />
          Back
        </div>
      )}
      {showContinueButton && (
        <a className="next-nav body-text" onClick={onContinue}>
          Continue
          <BackArrowIcon className="ml-10" />
        </a>
      )}
    </BottomNavigationWrap>
  );
};

interface BottomNavigationWrapProps {
  $fixed?: boolean;
}

const BottomNavigationWrap = styled.div<BottomNavigationWrapProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .next-nav,
  .back-nav {
    font-weight: bold;
    cursor: pointer;
    font-family: Gilroy-Medium;
    background: none !important;
    border: none !important;
  }

  .next-nav svg {
    transform: rotate(180deg);
  }

  svg path {
    stroke: var(--grey-dark);
  }

  @media (max-width: ${breakpoints.laptop}) {
    ${({ $fixed }) =>
      $fixed &&
      `
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--white);
  `}
  }
`;

export default BottomNavigation;
