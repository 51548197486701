import styled from 'styled-components';
import { CouponIcon } from 'assets/svg';
import Button from 'components/Button';
import { breakpoints } from 'constant/style';
import Link from 'next/link';
import { Brand, Voucher, VoucherV2 } from 'utils/types';
import React from 'react';
import { TERMS_AND_CONDITIONS_URL } from 'constant';
import { OfferCard } from '@momos/react-elements';

const PRIMARY_COLOR = '#baa0a0';

interface WelcomePageProps {
  voucherData?: Voucher;
  name: string;
  brand?: Brand;
  onStart: () => void;
}

const WelcomePage = ({ voucherData, name, brand, onStart }: WelcomePageProps) => {
  if (!voucherData || !brand) {
    return null;
  }

  return (
    <WelcomePageWrap>
      <div className="welcome-page-wrap">
        <div className="welcome-info">
          <div className="brand-name body-text">{name}</div>
          <h2>Hello! We are happy to offer you:</h2>
          <OfferCard voucher={voucherData as unknown as VoucherV2} />

          <div className="body-text mb-30 mt-20 font-medium">Complete this survey and redeem your reward!</div>
          <Button shape="round" className="start-btn mb-30" onClick={onStart}>
            Click here to start
          </Button>

          <div className="page-footer-text">
            <div className="mb-5">
              By proceeding you are agreeing to the <Link href={TERMS_AND_CONDITIONS_URL}>terms of conditions.</Link>
            </div>
            <div>This offer is for participating outlets only</div>
          </div>
        </div>
        <div className="welcome-img">
          <CouponIcon />
        </div>
      </div>

      <div className="welcome-page-mobile-wrap">
        <div className="brand-logo mb-20">
          <div className="brand-name body-text">{name}</div>
        </div>
        <div className="mb-40">
          <div className="greeting-text">Hello!</div>
          <div className="greeting-msg">We are happy to offer you:</div>
        </div>

        <div>
          <OfferCard voucher={voucherData as unknown as VoucherV2} />
        </div>

        <div className="body-text font-600 text-center mb-20">Complete this survey and redeem your reward!</div>

        <Button block shape="round" className="mb-20" onClick={onStart}>
          Click here to start
        </Button>

        <div className="page-footer-text">
          <div className="mb-5">
            By proceeding you are agreeing to the <Link href={TERMS_AND_CONDITIONS_URL}>terms of conditions.</Link>
          </div>
          <div>This offer is for participating outlets only</div>
        </div>
      </div>
    </WelcomePageWrap>
  );
};

const WelcomePageWrap = styled.div`
  .welcome-page-wrap {
    display: flex;
    align-items: center;

    .welcome-info {
      flex: 1;

      .brand-name {
        background: var(--smoke-white);
        display: inline-block;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 20px;
      }

      .discount-name span {
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        font-family: Gilroy-Medium;
      }

      .start-btn {
        width: 30%;
      }
    }

    .welcome-img {
      display: flex;
      justify-content: ceneter;
      align-items: center;
      padding: 40px;
    }

    .page-footer-text {
      font-family: Gilroy-Medium;
      font-size: 14px;
      line-height: 20px;
      color: var(--grey);

      a {
        color: ${PRIMARY_COLOR};
      }
    }

    @media (max-width: ${breakpoints.laptop}) {
      display: none;
    }
  }

  .welcome-page-mobile-wrap {
    @media (max-width: ${breakpoints.laptop}) {
      display: block;
    }

    @media (min-width: ${breakpoints.laptop}) {
      display: none;
    }

    .brand-logo {
      background: rgba(160, 160, 160, 0.05);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;
    }
    .greeting-text {
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 5px;
      font-family: Gilroy-Medium;
    }
    .greeting-msg {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      text-align: center;
      font-family: Gilroy-Medium;
    }

    .page-footer-text {
      font-family: Gilroy-Medium;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: var(--grey);

      a {
        color: ${PRIMARY_COLOR};
      }
    }
  }
`;

export default WelcomePage;
