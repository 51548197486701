import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Rate, Form, Upload, Row, Col, Skeleton } from 'antd';
import TextInput, { TextArea } from 'components/TextInput';
import classNames from 'classnames';
import { CloseIcon, DislikeIcon, LikeIcon, ClockIcon } from 'assets/svg';
import { breakpoints } from 'constant/style';
import BottomNavigation from 'components/BottomNavigation';
import { SENTIMENT_TYPE, SURVEY_ORDER_TYPES, TAG_TYPE } from 'constant/constants';
import DatePicker from 'components/DatePicker';
import TimePickerModal from 'components/TimePickerModal';
import request from 'config/request';
import endpoints from 'config/endpoints';
import { ArrowUpOutlined } from '@ant-design/icons';
import { bytesToSize } from 'utils/common';
import moment from 'moment';
import { UploadFile } from 'antd/lib/upload/interface';
import { SurveyQuestion, SurveySubmissionAnswerType } from 'utils/types';
import * as ga from 'lib/ga';
import { TRACKING } from 'constant/analytics';

const POSITIVE_SENTIMENTS: Record<string, string | null>[] = [
  { name: 'Taste', sentiment: null },
  { name: 'Ambience', sentiment: null },
  { name: 'Service', sentiment: null },
  { name: 'Price', sentiment: null },
  { name: 'Wait Time', sentiment: null },
  { name: 'Cleanliness', sentiment: null },
  { name: 'Family-friendly', sentiment: null },
];

const NEGATIVE_SENTIMENTS: Record<string, string | null>[] = [
  { name: 'Food Quality', sentiment: null },
  { name: 'Ambience', sentiment: null },
  { name: 'Service', sentiment: null },
  { name: 'Price', sentiment: null },
  { name: 'Wait Time', sentiment: null },
  { name: 'Item Missing', sentiment: null },
  { name: 'Received Wrong Order', sentiment: null },
  { name: 'Portion Size', sentiment: null },
];

interface FileListType extends UploadFile {
  mediaId: string;
}

interface RateExperienceProps {
  data: SurveyQuestion | null | undefined;
  surveyQuestionAnswered: any;
  setSurveyQuestionAnswered: (value: SurveySubmissionAnswerType) => void;
  orderType: string | null;
  onSubmit: (values: any) => void;
  onBack: () => void;
  slug: string;
  surveyId: string;
  fileList: FileListType[];
  setFileList: any;
  orderId: string | null;
  orderDate: string | null;
  reviewText: string;
}

const RateExperience = ({
  data,
  surveyQuestionAnswered,
  setSurveyQuestionAnswered,
  orderType,
  onSubmit,
  onBack,
  slug,
  surveyId,
  fileList,
  setFileList,
  orderId,
  orderDate,
  reviewText,
}: RateExperienceProps) => {
  const [ratingTags, setRatingTags] = useState<Record<string, string | null>[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (surveyQuestionAnswered?.answer) {
      setRatingTags(surveyQuestionAnswered?.answer > 3 ? POSITIVE_SENTIMENTS : NEGATIVE_SENTIMENTS);
    }
  }, [surveyQuestionAnswered?.answer]);

  const onSelectRatingTag = (index: number, value: string) => {
    const updatedSentiments = [...ratingTags];

    if (updatedSentiments[index].sentiment === value) {
      updatedSentiments[index].sentiment = '';
    } else {
      updatedSentiments[index].sentiment = value;
    }

    setRatingTags(updatedSentiments);
  };

  const handleRemove = (index: number) => {
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const uploadImage = async (options: any) => {
    setShowLoading(true);
    ga.event(TRACKING.SURVEY.BUTTON.UPLOAD_PICTURES);
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    fmData.append('media', file);
    fmData.append('type', 'survey');
    fmData.append('surveyId', surveyId);

    try {
      const result = await request.post(`${endpoints.momos.survey.default}/${slug}/media/upload`, fmData, config);
      setFileList([
        ...fileList,
        { ...result?.data, name: file.name, size: file.size, thumbUrl: result?.data?.mediaUrl },
      ]);
      onSuccess('Ok');
      setShowLoading(false);
    } catch (err) {
      onError({ err });
      setShowLoading(false);
    }
  };

  const disabledDate = (current: moment.Moment) => {
    return current && current > moment().endOf('day');
  };

  const onRate = (value: number) => {
    ga.event(TRACKING.SURVEY.BUTTON.RATING);
    setSurveyQuestionAnswered({
      ...surveyQuestionAnswered,
      answer: value.toString(),
    });
  };

  const onChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSurveyQuestionAnswered({
      ...surveyQuestionAnswered,
      detailedAnswer: e.target.value,
    });
  };

  const onFormSubmit = (values: Record<string, any>) => {
    const additionalData: SurveySubmissionAnswerType = {
      ...surveyQuestionAnswered,
      experienceData: {},
    };
    ratingTags?.map((ratingTag) => {
      if (ratingTag.name && ratingTag.sentiment) {
        additionalData.experienceData[ratingTag.name] =
          ratingTag.sentiment === SENTIMENT_TYPE.POSITIVE ? TAG_TYPE.LIKE : TAG_TYPE.DISLIKE;
      }
    });
    if (fileList && fileList.length) {
      additionalData['media'] = fileList.map((file) => file.mediaId);
    }
    setSurveyQuestionAnswered(additionalData);
    onSubmit(values);
  };

  useEffect(() => {
    form.setFieldsValue({
      reviewText: surveyQuestionAnswered.detailedAnswer || reviewText,
      orderTime: form.getFieldValue('orderTime') || moment(),
      orderDate: form.getFieldValue('orderDate') || moment(),
    });
  }, [surveyQuestionAnswered, form]);

  useEffect(() => {
    form.setFieldsValue({
      reviewText: reviewText,
    });
    if (reviewText) {
      setSurveyQuestionAnswered({
        ...surveyQuestionAnswered,
        detailedAnswer: reviewText,
      });
    }
  }, [reviewText]);

  useEffect(() => {
    form.setFieldsValue({
      orderId,
      orderDate: orderDate ? moment(orderDate) : form.getFieldValue('orderDate'),
      orderTime: orderDate ? moment(orderDate) : form.getFieldValue('orderTime'),
    });
  }, [form, orderId, orderDate]);

  return (
    <RateExperienceWrap>
      <Form form={form} layout="vertical" onFinish={onFormSubmit}>
        <div className="body-text-2 font-medium font-600 mb-20">Give us Feedback</div>
        <h2 className="mb-30">{data?.questionData?.text}</h2>
        <div className="body-text-2 font-medium font-600 mb-15">Rate your experience</div>
        <div className="flex mr-15 mb-20">
          <Rate
            value={surveyQuestionAnswered?.answer}
            onChange={onRate}
            className="custom-stars"
            style={{ color: '#D84339' }}
          />
        </div>

        {surveyQuestionAnswered?.answer > 0 && (
          <>
            <div className="response-block">
              <Form.Item
                name="reviewText"
                label={data?.questionData?.subtext}
                className="label-text flex-1"
                rules={[{ required: true, message: 'Please enter a comment' }]}
              >
                <TextArea
                  placeholder="Enter comment"
                  onChange={onChangeMessage}
                  onClick={() => ga.event(TRACKING.SURVEY.BUTTON.INPUT_MESSAGE)}
                  rows={4}
                />
              </Form.Item>
            </div>
            <div className="user-feedback-wrap">
              <div className="ratings-fields-wrap mb-20">
                <div className="body-text font-medium font-600 mb-20">Please rate all aspects of your experience</div>

                <div className="sentiments-wrap">
                  {ratingTags?.map((tag, key) => {
                    return (
                      <div className="sentiment" key={key}>
                        <div className="tag-text">{tag.name}</div>
                        <div className="tag-actions">
                          <div
                            className={classNames('tag-icon like mr-20', {
                              active: tag.sentiment === SENTIMENT_TYPE.POSITIVE,
                            })}
                            onClick={() => {
                              ga.event(TRACKING.SURVEY.BUTTON.THUMBS_UP);
                              onSelectRatingTag(key, SENTIMENT_TYPE.POSITIVE);
                            }}
                          >
                            <LikeIcon />
                          </div>
                          <div
                            className={classNames('tag-icon dislike', {
                              active: tag.sentiment === SENTIMENT_TYPE.NEGATIVE,
                            })}
                            onClick={() => {
                              ga.event(TRACKING.SURVEY.BUTTON.THUMBS_DOWN);
                              onSelectRatingTag(key, SENTIMENT_TYPE.NEGATIVE);
                            }}
                          >
                            <DislikeIcon />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {(orderType === SURVEY_ORDER_TYPES.DINE_IN || orderType === SURVEY_ORDER_TYPES.TAKE_AWAY) && (
                <div className="order-date-wrap survey-inputs">
                  <Form.Item name="orderDate" label="Visit date" className="label-text flex-1">
                    <DatePicker
                      className="w-100"
                      disabledDate={disabledDate}
                      onClick={() => ga.event(TRACKING.SURVEY.BUTTON.VISIT_DATE)}
                    />
                  </Form.Item>
                  <Form.Item name="orderTime" label="Visit time" className="label-text flex-1">
                    <TimePickerModal
                      onChange={(time) => {
                        ga.event(TRACKING.SURVEY.BUTTON.VISIT_TIME);
                        form.setFieldsValue({ orderTime: time });
                      }}
                      minStep={1}
                      suffix={<ClockIcon />}
                    />
                  </Form.Item>
                </div>
              )}

              <div className="order-date-wrap">
                <Form.Item name="orderId" label="Order Reference Number" className="flex-1">
                  <TextInput
                    placeholder="Reference number"
                    onClick={() => ga.event(TRACKING.SURVEY.BUTTON.ORDER_REFERENCE)}
                  />
                </Form.Item>
              </div>

              <Upload
                customRequest={uploadImage}
                maxCount={3}
                disabled={fileList?.length === 3}
                showUploadList={false}
                fileList={fileList}
                accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF,.heic,.HEIC"
              >
                <ActionArea>
                  <Icon>
                    <ArrowIcon />
                  </Icon>
                  <div>Upload pictures / files you wish to share with the restaurant</div>
                </ActionArea>
              </Upload>

              {showLoading ? (
                <Skeleton active />
              ) : (
                <Row gutter={[16, 16]} className="mb-20">
                  {fileList?.map((file, index) => (
                    <Col key={index} lg={24 / fileList?.length}>
                      <div className="upload-media-list">
                        <div className="media-image-wrap">
                          <img className="media-image" alt="Thumbnail Image" src={file.thumbUrl} />
                        </div>
                        <div className="media-info">
                          <div className="media-name">{file.name}</div>
                          {file.size && <div className="media-size">{bytesToSize(file.size)}</div>}
                        </div>
                        <div className="close-icon-wrap" onClick={() => handleRemove(index)}>
                          <CloseIcon />
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </>
        )}

        <BottomNavigation
          onBack={onBack}
          onContinue={() => {
            ga.event(TRACKING.SURVEY.BUTTON.SUBMIT_RATE_EXPERIENCE);
            form.submit();
          }}
          showContinueButton={surveyQuestionAnswered?.answer > 0}
          fixed={false}
        />
      </Form>
    </RateExperienceWrap>
  );
};

const RateExperienceWrap = styled.div`
  width: 50%;

  .custom-stars {
    svg {
      width: 26px;
      height: 26px;
    }
  }

  .response-block {
    margin-bottom: 20px;

    .ant-input.ant-input-lg {
      line-height: initial !important;
    }

    .ant-form-item-with-help .ant-form-item-explain {
      padding-left: 11px;
    }
  }

  .user-feedback-wrap {
    .order-date-wrap {
      display: flex;
      margin-bottom: 30px;
      margin-top: 25px;

      .ant-picker-clear {
        opacity: 1;
      }

      .survey-inputs {
        .ant-input.ant-input-lg {
          padding: 0px;
          font-family: Gilroy-Regular;
          border: none;
        }
      }

      .ant-input.ant-input-lg {
        font-size: 16px !important;
        font-family: Gilroy-Regular;
        line-height: 20px !important;
      }

      .momos-input {
        height: auto;

        svg path {
          fill: var(--grey);
        }
      }

      > div {
        margin-right: 15px;
      }

      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
    }

    .ant-select-selection-item {
      font-size: 16px;
      font-family: Gilroy-Medium;
    }
  }

  .sentiments-wrap {
    .sentiment {
      display: flex;
      align-items: center;
      padding: 20px 10px;
      cursor: pointer;
      margin-bottom: 5px;
      transition: all 300ms ease 0s;
      border-bottom: 1px solid var(--grey-light);

      &:last-child {
        border-bottom: none;
      }

      .tag-text {
        flex: 1;
        font-size: 14px;
        line-height: 20px;
        font-family: Gilroy-Medium;
        font-weight: bold;
      }

      .tag-actions {
        display: flex;
        align-items: center;

        .tag-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          &.like {
            border: 1px solid var(--green);

            svg > path {
              stroke: var(--green);
            }

            &.active {
              background: var(--green);

              svg > path {
                stroke: var(--white);
              }
            }
          }

          &.dislike {
            border: 1px solid var(--orange);

            svg > path {
              stroke: var(--orange);
            }

            &.active {
              background: var(--orange);

              svg > path {
                stroke: var(--white);
              }
            }
          }
        }
      }
    }
  }

  .form-wrap {
    display: flex;
    align-items: center;

    > div:first-child {
      margin-right: 15px;
    }
  }

  .survey-sumbit-btn {
    width: 80%;
  }

  .responsive-navigation {
    display: none;
  }

  .upload-media-list {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;

    .media-image-wrap {
      margin-right: 10px;

      .media-image {
        object-fit: cover;
        border-radius: 40px;
        width: 50px;
        height: 45px;
      }
    }

    .media-info {
      margin-right: 10px;
      flex: 1;

      .media-name {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #30384c;
        word-break: break-all;
      }
      .media-size {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: #a0a0a0;
      }
    }

    .close-icon-wrap {
      cursor: pointer;
    }
  }

  @media (max-width: ${breakpoints.laptop}) {
    width: 100%;

    .response-block {
      margin-bottom: 10px;

      > div {
        margin-bottom: 0px;
      }
    }

    .user-feedback-wrap {
      display: flex;
      flex-direction: column-reverse;

      .order-date-wrap {
        margin-top: 10px;
        flex-direction: column;

        > div {
          margin-right: 0px;
          margin-bottom: 25px;
        }
      }
    }

    .form-wrap {
      display: flex;
      flex-direction: column;
      align-items: inherit;

      > div:first-child {
        margin-right: 0px;
        margin-bottom: 15px;
      }
    }

    .survey-sumbit-btn {
      display: none;
    }
  }

  .ant-upload-list.ant-upload-list-picture {
    display: flex;

    .ant-upload-list-picture-container {
      margin-right: 10px;
    }
  }
`;

const ActionArea = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: var(--orange);
  }
`;

const Icon = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--orange);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const ArrowIcon = styled(ArrowUpOutlined)`
  color: var(--white);
`;

export default RateExperience;
