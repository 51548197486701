import React from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { breakpoints } from 'constant/style';
import { NoVoucherIcon } from 'assets/svg';

const PRIMARY_COLOR = '#baa0a0';

interface NoVoucherProps {
  name: string;
  onStart: () => void;
}

const NoVoucher = ({ name, onStart }: NoVoucherProps) => {
  return (
    <NoVoucherWrap>
      <div className="welcome-page-wrap">
        <div className="welcome-info">
          <div className="brand-name body-text font-600">{name}</div>

          <div className="welcom-page-content">
            <div className="hello-text">Hello!</div>
          </div>

          <div className="banner-wrap">
            <div className="banner-img">
              <NoVoucherIcon />
            </div>
          </div>

          <div className="body-text font-600 mb-15">Your feedback matters a lot to us</div>

          <Button shape="round" className="start-btn mb-30" onClick={onStart}>
            Start 1 minute survey
          </Button>
        </div>
      </div>
    </NoVoucherWrap>
  );
};

const NoVoucherWrap = styled.div`
  padding-top: 40px;

  .welcome-page-wrap {
    display: flex;
    align-items: center;
    width: 60%;
    margin: 0 auto;

    .welcome-info {
      flex: 1;
      text-align: center;

      .brand-name {
        background: var(--smoke-white);
        display: inline-block;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 20px;
      }

      .welcom-page-content {
        margin-bottom: 60px;

        .hello-text {
          font-weight: 600;
          font-size: 18px;
          line-height: 20px;
          color: #30384c;
          font-family: Gilroy-Medium;
          margin-bottom: 5px;
        }
      }

      .banner-wrap {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: var(--snow);
        margin-bottom: 20px;
        padding: 0px 25%;

        .banner-img {
          svg {
            margin-top: -40px;
          }
        }
      }

      .start-btn {
        width: 30%;
      }
    }

    .welcome-img {
      display: flex;
      justify-content: ceneter;
      align-items: center;
      padding: 40px;
    }

    .page-footer-text {
      font-family: Gilroy-Medium;
      font-size: 14px;
      line-height: 20px;
      color: var(--grey);

      a {
        color: ${PRIMARY_COLOR};
      }
    }
  }

  @media (max-width: ${breakpoints.laptop}) {
    .welcome-page-wrap {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;

      .welcome-info {
        .banner-wrap {
          padding: 0px;
        }

        .start-btn {
          width: 100%;
        }
      }

      .page-footer-text {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`;

export default NoVoucher;
