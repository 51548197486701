import React from 'react';
import styled from 'styled-components';
import { DeliveryIcon, DineInIcon, TakeawayIcon } from 'assets/svg';
import { Col, Row } from 'components/Layout/RowColLayout';
import { breakpoints } from 'constant/style';
import BottomNavigation from 'components/BottomNavigation';
import { SURVEY_ORDER_TYPES } from 'constant/constants';
import { REGION, REGIONS } from 'constant';

interface MethodOfOrderProps {
  onSelect: (type: string) => void;
  onBack: () => void;
  brandColor: string;
}

const MethodOfOrder = ({ onSelect, onBack, brandColor }: MethodOfOrderProps) => {
  return (
    <MethodOfOrderWrap $brandColor={brandColor}>
      <h2>How did you place your order with us?</h2>

      <Row className="service-options-wrap" gutter={[20, 20]}>
        <Col lg={12} xs={24}>
          <div className="service-option" onClick={() => onSelect(SURVEY_ORDER_TYPES.DINE_IN)}>
            <DineInIcon />
            <div className="service-option-txt">Dine-in</div>
          </div>
        </Col>
        <Col lg={12} xs={24}>
          <div className="service-option" onClick={() => onSelect(SURVEY_ORDER_TYPES.DELIVERY)}>
            <DeliveryIcon />
            <div className="service-option-txt">Delivery</div>
          </div>
        </Col>
        <Col lg={12} xs={24}>
          <div className="service-option" onClick={() => onSelect(SURVEY_ORDER_TYPES.TAKE_AWAY)}>
            <TakeawayIcon />
            <div className="service-option-txt">{REGION === REGIONS.US ? 'Takeout' : 'Takeaway'}</div>
          </div>
        </Col>
      </Row>
      <BottomNavigation onBack={onBack} />
    </MethodOfOrderWrap>
  );
};

interface MethodOfOrderWrapProps {
  $brandColor: string;
}

const MethodOfOrderWrap = styled.div<MethodOfOrderWrapProps>`
  width: 70%;
  margin-top: 20px;

  h2 {
    margin-bottom: 40px;
  }

  .service-options-wrap {
    margin-bottom: 40px;

    .service-option {
      border: 1px solid var(--grey-light);
      box-sizing: border-box;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 300ms ease 0s;

      .service-option-txt {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        font-family: Gilroy-Medium;
      }

      svg {
        margin-right: 30px;
      }

      svg path {
        stroke: ${(props) => props.$brandColor};
      }

      :hover {
        background: ${(props) => props.$brandColor};

        svg path {
          stroke: var(--white);
        }

        .service-option-txt {
          color: var(--white);
        }
      }
    }
  }

  @media (max-width: ${breakpoints.laptop}) {
    width: 100%;
  }
`;

export default MethodOfOrder;
