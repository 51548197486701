import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'components/Layout/RowColLayout';
import PlatformCard from 'components/PlatformCard';
import { breakpoints } from 'constant/style';
import BottomNavigation from 'components/BottomNavigation';
import { ThirdPartyLinksType } from 'constant/constants';
import { ThirdPartyLink } from 'utils/types';

interface SelectPlatformProps {
  onSelect: (type: string) => void;
  onBack: () => void;
  selectedLocation: Record<string, any> | null | undefined;
  categories: ThirdPartyLink[];
  brand: any;
  brandColor: string;
}

const SelectPlatform = ({ onSelect, onBack, selectedLocation, categories, brand, brandColor }: SelectPlatformProps) => {
  const PLATFORMS = useMemo(() => {
    const platformsLogo: { id: string; name: string; logo: string | undefined }[] = [];
    selectedLocation?.thirdPartyLinks?.map((link: Record<string, any>) => {
      if (link.type === ThirdPartyLinksType.FOOD_DELIVERY) {
        const category = categories?.find((o) => o.id === link.categoryId);
        if (category && !platformsLogo.some((o) => o.id === category.id) && category.category !== 'Other') {
          platformsLogo.push({ id: category.id, name: category.category, logo: category.logo });
        }
      }
    });
    return platformsLogo;
  }, [selectedLocation, categories]);

  return (
    <SelectPlatformWrap>
      <h2 className="mb-30">Which food delivery platform did you order from?</h2>

      <Row gutter={[20, 20]} className="mb-40">
        {PLATFORMS?.map((platformObj) => {
          if (platformObj.name === 'Your Website') {
            return (
              <Col lg={6} sm={12} xs={12} key={platformObj.id}>
                <PlatformCard
                  platformLogo={brand.logoUrl}
                  onClick={() => onSelect(platformObj.name)}
                  maxHeight="150px"
                  platformName={brand.name}
                  theme={{ highlightColor: brandColor }}
                />
              </Col>
            );
          }
          return (
            <Col lg={6} sm={12} xs={12} key={platformObj.id}>
              <PlatformCard
                platformLogo={platformObj.logo}
                onClick={() => onSelect(platformObj.name)}
                maxHeight="150px"
                platformName={platformObj.name}
                theme={{ highlightColor: brandColor }}
              />
            </Col>
          );
        })}

        <Col lg={6} sm={12} xs={12}>
          <PlatformCard
            onClick={() => onSelect('OTHER')}
            maxHeight="150px"
            platformName="Other"
            theme={{ highlightColor: brandColor }}
          />
        </Col>
      </Row>

      <BottomNavigation onBack={onBack} />
    </SelectPlatformWrap>
  );
};

const SelectPlatformWrap = styled.div`
  width: 60%;
  margin-top: 20px;

  .custom-website-text {
    font-family: Gilroy-Medium;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }

  @media (max-width: ${breakpoints.laptop}) {
    width: 100%;

    .custom-website-text {
      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export default SelectPlatform;
